import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  slackResponse: null,
  loading: false,
  done: false,
  error: false,
};

export const BASE_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

export const slackSendMessageToHR = createAsyncThunk(
  'slack/slackHR',
  async (data, thunkAPI) => {
    try {
      const response = await axios.post('/slack/slack-message', data, {
        headers: {
          'Content-type': 'application/json',
        },
      });

      return response.data;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const slackSendMessageToAll = createAsyncThunk(
  'users/slackAll',
  async (thunkAPI) => {
    try {
      const response = await axios.get('/slack/slack-message-to-all', {
        headers: {
          'Content-type': 'application/json',
        },
      });

      return response.data;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const slackSlice = createSlice({
  name: 'slack',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(slackSendMessageToHR.pending, (state) => {
        state.loading = true;
      })
      .addCase(slackSendMessageToHR.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.slackResponse = null;
      })
      .addCase(slackSendMessageToHR.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(slackSendMessageToAll.pending, (state) => {
        state.loading = true;
      })
      .addCase(slackSendMessageToAll.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.slackResponse = null;
      })
      .addCase(slackSendMessageToAll.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default slackSlice.reducer;
