import moment from 'moment';

export const formatDate = (date) => {
  console.log('date', date);
  return date ? moment(date).format('LL') : '---';
};

export const formatTime = (date) => {
  console.log('date', date);
  return date ? moment(date).format('hh:mm A') : '---';
};
