import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  allRequests: [],
  pastRequests: null,
  pendingRequests: null,
  allEmployeeRequests: null,
  loading: false,
  done: false,
  error: false,
  message: null,
};

export const BASE_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

export const getPendingRequest = createAsyncThunk(
  'reimbursement/getPendingRequest',
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(
        `/reimbursement/get-all-pending-reimbursement?page=${params.page}&limit=${params.limit}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data?.data;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPastRequests = createAsyncThunk(
  'reimbursement/getPastRequests',
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(
        `/reimbursement/get-all-past-reimbursement?page=${params.page}&limit=${params.limit}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data?.data;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getPastRequestsByEmployeeID = createAsyncThunk(
  'getPastRequestsByEmployeeID',
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(
        `/reimbursement/get-past-reimbursement-by-employeeId?_id=${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      return response.data?.data;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getRequestsByEmployeeID = createAsyncThunk(
  'reimbursement/getRequestsByEmployeeID',
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(
        `/reimbursement/get-reimbursement-by-employeeID?_id=${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      return response.data?.data;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getPendingRequestsByEmployeeID = createAsyncThunk(
  'reimbursement/getPendingRequestsByEmployeeID',
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(
        `/reimbursement/get-pending-reimbursement-by-employeeId?_id=${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data?.data;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const submitNewRequest = createAsyncThunk(
  'reimbursement/submitNewRequest',
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        '/reimbursement/add-reimbursement',
        data,
        {
          headers: {
            'Content-type': 'multipart/form-data',
          },
        }
      );

      return response?.data;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateRequestStatus = createAsyncThunk(
  'reimbursement/updateRequestStatus',
  async (data, thunkAPI) => {
    try {
      const response = await axios.patch(
        '/reimbursement/update-reimbursement',
        data,
        {
          headers: {
            'Content-type': 'application/json',
          },
        }
      );

      return response.data?.data;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteReimbursement = createAsyncThunk(
  'reimbursement/deleteReimbursement',
  async (id, thunkAPI) => {
    try {
      const response = await axios.delete(
        `/reimbursement/delete-reimbursement?_id=${id}`,
        {
          headers: {
            'Content-type': 'application/json',
          },
        }
      );

      return response.data?.data;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const reimbursementSlice = createSlice({
  name: 'reimbursement',
  initialState,
  reducers: {
    resetGenericStates: (state) => {
      state.error = false;
      state.loading = false;
      state.message = null;
      state.done = false;
    },
    resetAllTabStates: (state, action) => {
      state.allEmployeeRequests = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPendingRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPendingRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.allRequests = action.payload;
      })
      .addCase(getPendingRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.allRequests = [];
      })
      .addCase(getPastRequests.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPastRequests.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.pastRequests = action.payload;
      })
      .addCase(getPastRequests.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.pastRequests = null;
      })
      .addCase(getPastRequestsByEmployeeID.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPastRequestsByEmployeeID.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.pastRequests = action.payload;
      })
      .addCase(getPastRequestsByEmployeeID.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.pastRequests = null;
      })
      .addCase(getPendingRequestsByEmployeeID.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPendingRequestsByEmployeeID.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.pendingRequests = action.payload;
      })
      .addCase(getPendingRequestsByEmployeeID.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.pendingRequests = null;
      })
      .addCase(getRequestsByEmployeeID.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRequestsByEmployeeID.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.allEmployeeRequests = action.payload;
      })
      .addCase(getRequestsByEmployeeID.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.allEmployeeRequests = null;
      })
      .addCase(submitNewRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(submitNewRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.message = action?.payload?.message;
        state.allRequests = [...state.allRequests, action.payload?.data];
      })
      .addCase(submitNewRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.message = action?.payload?.message
          ? action?.payload?.message
          : action?.payload?.data;
      })
      .addCase(updateRequestStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateRequestStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.allRequests = state.allRequests.filter((item) => {
          return item.status === 'Pending' && item._id !== action.payload?._id;
        });
      })
      .addCase(updateRequestStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.allRequests = [];
      })
      .addCase(deleteReimbursement.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteReimbursement.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.allRequests = state.allRequests.filter((item) => {
          return item.id !== action.payload?.data?._id;
        });
      })
      .addCase(deleteReimbursement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export const { resetGenericStates, resetAllTabStates } =
  reimbursementSlice.actions;
export default reimbursementSlice.reducer;
