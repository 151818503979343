import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { logoutUser, resetUser } from '../../Redux/slices/authSlice';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import { DRAWER_WIDTH as drawerWidth } from '../../Utils/Constants';
import LayoutSpacing from '../../Utils/LayoutSpacing';
import Colors from '../../Utils/Colors';
import { CHANGE_PASSWORD, MY_PROFILE } from '../../Routes/route_constants';

const ITEM_HEIGHT = 48;
const options = ['Profile', 'Change Password', 'Logout'];

const LayoutHeader = ({ open, handleSidedrawer }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation().state?.redirectedFrom?.pathname;
  const user = useSelector((state) => state.auth.currentUser?.data);
  const organizations = useSelector(
    (state) => state.users.userOrganizations || []
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const menuOpen = Boolean(anchorEl);

  const handleToggleMenu = (event) => setAnchorEl(event.currentTarget);

  const handleCloseMenu = () => setAnchorEl(null);

  const handleOptionClick = async (option) => {
    handleCloseMenu();
  
    if (option === 'Profile') {
      navigate(MY_PROFILE);
    } else if (option === 'Change Password') {
      navigate(CHANGE_PASSWORD);
    } else if (option === 'Logout') {
      try {
        
        await dispatch(logoutUser()).unwrap();
        
        dispatch(resetUser());
        
        navigate(location || '/', { replace: true });
      } catch (error) {
        console.error('Logout failed:', error);

      }
    }
  };

  return (
    <>
      <MuiAppBar
        position="fixed"
        open={open}
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          boxShadow: 'none',
          backgroundColor: 'transparent',
          p: LayoutSpacing.headerPadding,
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: LayoutSpacing.headerBorderRadius,
            backgroundColor: 'white',
            // backgroundColor: Colors.primary,
            borderBottom: `1px solid ${Colors.border}`,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleSidedrawer}
              sx={{
                mr: 2,
                display: { sm: 'none' },
                '& svg': { color: Colors.text },
              }}
            >
              <MenuIcon />
            </IconButton>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1,
              justifyContent: 'flex-end',
            }}
          >
            <Typography
              noWrap
              component="div"
              variant="h6"
              sx={{
                display: { xs: 'none', sm: 'block' },
                // color: 'white',
                // fontSize: '16px',
                // fontWeight: 500,
              }}
            >
              {user?.first_name}
            </Typography>
          </Box>

          <Avatar
            sx={{
              fontSize: '22px',
              bgcolor: Colors.text,
              ml: '12px',
              fontWeight: 500,
              transition: 'background-color 0.3s',
              '&:hover': {
                bgcolor: Colors.textMid,
                cursor: 'pointer',
              },
              width: '38px',
              height: '38px',
            }}
            aria-label="more"
            id="long-button"
            aria-controls={menuOpen ? 'long-menu' : undefined}
            aria-expanded={menuOpen ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleToggleMenu}
          >
            {user?.first_name.charAt(0)}
          </Avatar>

          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleCloseMenu}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: '20ch',
                boxShadow: 'rgba(17, 17, 26, 0.1) 0px 0px 16px',
                borderRadius: '12px',
              },
            }}
          >
            {options.map((option) => (
              <MenuItem key={option} onClick={() => handleOptionClick(option)}>
                {option}
              </MenuItem>
            ))}
          </Menu>
        </Toolbar>
      </MuiAppBar>
    </>
  );
};

export default LayoutHeader;

