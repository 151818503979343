import { useDispatch, useSelector } from 'react-redux';
import './LeaveManagement.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateLeave } from '../../Redux/slices/leaveSlice';
import DynamicBackButton from '../../Components/BackButton/DynamicBackButton';
import { Box, Typography } from '@mui/material';

import {
  LEAVE_STATUS_ENUMS,
  LEAVE_TYPE_NAMES_ENUMS,
} from '../../Utils/Constants';
import { useEffect, useState } from 'react';
import { getUserById } from '../../Redux/slices/userSlice';
import { EMPLOYEE_LEAVE_REQUESTS } from '../../Routes/route_constants';
import CustomDialog from '../../Components/MiscComponents/CustomDialog';
import CustomButton from '../../Components/MiscComponents/CustomButton';
import { Close, Done } from '@mui/icons-material';
import Colors from '../../Utils/Colors';

const ReviewLeave = (props) => {
  const { open, onClose, leave } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // const leave = location.state.leave;
  const loading = useSelector((state) => state.leaves.loading);

  const [approvePopup, setApprovePopup] = useState(false);
  const [rejectPopup, setRejectPopup] = useState(false);

  const [employeeLeaveData, setEmployeeLeaveData] = useState([]);

  const [paidLeave, setPaidLeave] = useState(true);

  const employeeLeaveDataResponse = useSelector(
    (state) => state.users.userDetails
  );

  useEffect(() => {
    if (
      leave &&
      (!employeeLeaveDataResponse || !employeeLeaveDataResponse?.length)
    ) {
      dispatch(getUserById(leave?.employee?._id));
    }
  }, []);

  useEffect(() => {
    if (employeeLeaveDataResponse) {
      setEmployeeLeaveData(employeeLeaveDataResponse?.leave_records);
    }
  }, [employeeLeaveDataResponse]);

  useEffect(() => {
    if (
      leave?.type === LEAVE_TYPE_NAMES_ENUMS[0] &&
      employeeLeaveData?.casual_leaves === 0
    ) {
      setPaidLeave(false);
    }
    if (
      leave?.type === LEAVE_TYPE_NAMES_ENUMS[1] &&
      employeeLeaveData?.sick_leaves === 0
    ) {
      setPaidLeave(false);
    }
    if (
      leave?.type === LEAVE_TYPE_NAMES_ENUMS[2] &&
      employeeLeaveData?.annual_leaves === 0
    ) {
      setPaidLeave(false);
    }
  }, [employeeLeaveData]);

  const handleApproveLeave = () => {
    dispatch(
      updateLeave({
        ...leave,
        status: LEAVE_STATUS_ENUMS[1],
        employee: leave?.employee?._id,
      })
    );
    onClose();
    navigate(EMPLOYEE_LEAVE_REQUESTS);
  };

  const handleRejectLeave = () => {
    dispatch(
      updateLeave({
        ...leave,
        status: LEAVE_STATUS_ENUMS[2],
        employee: leave?.employee?._id,
      })
    );
    onClose();
    navigate(EMPLOYEE_LEAVE_REQUESTS);
  };

  return (
    <Box>
      <DynamicBackButton />
      <CustomDialog
        open={approvePopup}
        onClose={() => setApprovePopup(false)}
        heading="Approve Leave"
        size="sm"
        subHeading={`Are you sure you want to approve this leave request for ${leave?.employee?.first_name} ${leave?.employee?.last_name} - ${leave.type} ?`}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          gap="8px"
          mt="32px"
        >
          <CustomButton
            onClick={() => setApprovePopup(false)}
            variant="outlined"
          >
            Cancel
          </CustomButton>
          <CustomButton onClick={handleApproveLeave}>Yes</CustomButton>
        </Box>
      </CustomDialog>

      {/* Reject Popup */}
      <CustomDialog
        open={rejectPopup}
        onClose={() => setRejectPopup(false)}
        heading="Reject Request"
        size="sm"
        subHeading={`Are you sure you want to reject this leave request for 
            ${leave?.employee?.first_name} ${leave?.employee?.last_name} - 
            ${leave.type}?`}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          gap="8px"
          mt="32px"
        >
          <CustomButton
            onClick={() => setRejectPopup(false)}
            variant="outlined"
          >
            Cancel
          </CustomButton>
          <CustomButton onClick={handleRejectLeave}>Yes</CustomButton>
        </Box>
      </CustomDialog>

      <CustomDialog
        open={open}
        onClose={onClose}
        heading="Leave Details"
        size="md"
      >
        <Box
          display="flex"
          flexDirection="column"
          gap="8px"
          mt="24px"
          sx={{
            '& span': { mr: '4px', fontWeight: 400, color: Colors.textMid },
          }}
        >
          <Typography fontWeight={500} variant="body1">
            <span>Employee Name:</span> {leave?.employee?.first_name}{' '}
            {leave?.employee?.last_name}
          </Typography>

          <Typography fontWeight={500} variant="body1">
            <span>Type:</span> {leave.type} ({paidLeave ? 'Paid' : 'Unpaid'})
          </Typography>

          {leave?.type === LEAVE_TYPE_NAMES_ENUMS[0] && (
            <Typography fontWeight={500} variant="body1">
              <span>Remaining Casual Leaves:</span>{' '}
              {employeeLeaveData?.casual_leaves}
            </Typography>
          )}

          {leave?.type === LEAVE_TYPE_NAMES_ENUMS[2] && (
            <Typography fontWeight={500} variant="body1">
              <span>Remaining Annual Leaves:</span>{' '}
              {employeeLeaveData?.annual_leaves}
            </Typography>
          )}

          {leave?.type === LEAVE_TYPE_NAMES_ENUMS[1] && (
            <Typography fontWeight={500} variant="body1">
              <span>Remaining Sick Leaves:</span>{' '}
              {employeeLeaveData?.sick_leaves}
            </Typography>
          )}

          <Typography fontWeight={500} variant="body1">
            <span>Start Date:</span>{' '}
            {new Date(leave.start_date).toLocaleDateString()}
          </Typography>

          <Typography fontWeight={500} variant="body1">
            <span>End Date:</span>{' '}
            {leave.end_date
              ? new Date(leave.end_date).toLocaleDateString()
              : 'N/A'}
          </Typography>

          <Typography fontWeight={500} variant="body1">
            <span>Status:</span> {leave.status}
          </Typography>

          <Typography fontWeight={500} variant="body1">
            <span>Reason:</span> {leave.reason || 'N/A'}
          </Typography>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          gap="8px"
          mt="32px"
        >
          <CustomButton
            onClick={() => setRejectPopup(true)}
            variant="outlined"
            endIcon={<Close />}
          >
            Decline
          </CustomButton>
          <CustomButton
            onClick={() => setApprovePopup(true)}
            endIcon={<Done />}
          >
            Approve
          </CustomButton>
        </Box>
      </CustomDialog>
    </Box>
  );
};
export default ReviewLeave;
