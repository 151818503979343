import React from 'react';
import {
  Box,
  TextField,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { PROJECT_STATUS_OBJ_ENUMS } from '../../Utils/Constants';
import CustomDialog from '../../Components/MiscComponents/CustomDialog';
import CustomButton from '../../Components/MiscComponents/CustomButton';

const EditProjectDialog = ({
  open,
  onClose,
  heading = 'Edit Project',
  editedProject = {},
  handleEditChange = () => {},
  setProjectManagerSearch = () => {},
  onSave = () => {},
  dateError,
  projectDetail,
  employees,
  setMemberSearch,
}) => {
  return (
    <CustomDialog open={open} onClose={onClose} heading={heading}>
      <Box
        sx={{
          mt: '24px',
          display: 'flex',
          flexDirection: 'column',
          gap: '18px',
        }}
      >
        <TextField
          label="Project Name"
          variant="outlined"
          name="name"
          value={editedProject.name}
          onChange={handleEditChange}
          fullWidth
        />
        <TextField
          label="Description"
          variant="outlined"
          name="description"
          value={editedProject.description}
          onChange={handleEditChange}
          fullWidth
          multiline
          rows={4}
        />
        <Box display="grid" gridTemplateColumns="1fr 1fr" gap="inherit">
          <TextField
            label="Start Date"
            type="date"
            name="start_date"
            value={editedProject.start_date}
            onChange={handleEditChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="End Date"
            type="date"
            name="end_date"
            value={editedProject.end_date}
            onChange={handleEditChange}
            error={dateError}
            helperText={dateError ? "End date can't be before start date" : ''}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <FormControl fullWidth>
          <InputLabel id="status-label">Project Status</InputLabel>
          <Select
            labelId="status-label"
            id="status"
            name="status" // Make sure to include the name attribute
            value={editedProject.status} // Use editedProject.status instead of formValues.status
            label="Project Status"
            onChange={(event) =>
              handleEditChange(event.target.name, event.target.value)
            }
          >
            {PROJECT_STATUS_OBJ_ENUMS.map((status) => (
              <MenuItem key={status.value} value={status.value}>
                {status.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Autocomplete
          options={employees}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          onInputChange={(event, newInputValue) => {
            setProjectManagerSearch(newInputValue);
          }}
          onChange={(event, newValue) => {
            handleEditChange('project_manager', newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select New Project Manager"
              fullWidth
            />
          )}
        />
        <Autocomplete
          multiple
          options={employees}
          getOptionLabel={(option) => option.label}
          filterSelectedOptions
          value={editedProject.members}
          onInputChange={(event, newInputValue) => {
            setMemberSearch(newInputValue);
          }}
          onChange={(event, newValue) => {
            handleEditChange('members', newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Project Members" fullWidth />
          )}
        />
        <TextField
          label="Current Project Manager"
          variant="outlined"
          name="project_manager"
          value={`${projectDetail.project_manager?.first_name} ${projectDetail.project_manager?.last_name}`}
          InputProps={{
            readOnly: true,
          }}
          fullWidth
        />
      </Box>

      <Box display="flex" justifyContent="flex-end" mt="42px" gap="8px">
        <CustomButton variant="outlined" onClick={onClose}>
          Cancel
        </CustomButton>
        <CustomButton variant="contained" color="primary" onClick={onSave}>
          Save Changes
        </CustomButton>
      </Box>
    </CustomDialog>
  );
};

export default EditProjectDialog;
