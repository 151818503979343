import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  loggedInUser: null,
  loginResponse: null,
  logoutResponse: null,
  currentUser: null,
  forgetPasswordResponse: null,
  resetPasswordResponse: null,
  message: null,
  loading: false,
  done: false,
  error: false,
};

export const BASE_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

export const logoutUser = createAsyncThunk(
  'auth/logout',
  async (_, thunkAPI) => {
    try {
      const response = await axios.post(
        '/v1/auth/logout',
        {},
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const loginUser = createAsyncThunk(
  'auth/login',
  async (data, thunkAPI) => {
    try {
      // Corrected the endpoint to match the provided URL
      const response = await axios.post('/v1/auth/login', data, {
        headers: {
          'Content-type': 'application/json',
        },
      });

      return response.data;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Updated forgotPassword to match the backend route
export const forgotPassword = createAsyncThunk(
  'auth/forgetPassword',
  async (data, thunkAPI) => {
    try {
      const response = await axios.post('/v1/auth/send-reset-link', data, {
        headers: {
          'Content-type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// New action for getting the current user
export const getCurrentUser = createAsyncThunk(
  'auth/getCurrentUser',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get('/v1/auth/me', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Updated resetPassword to match the backend route
export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (data, thunkAPI) => {
    try {
      const response = await axios.post('/v1/auth/reset-password', data, {
        headers: {
          'Content-type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const setPassword = createAsyncThunk(
  'auth/setPassword',
  async ({ password, token }, thunkAPI) => {
    try {
    
      const payload = {
        password,
        token
      };

      const response = await axios.post('/v1/auth/set-password', payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetGenericStates: (state) => {
      state.error = false;
      state.loading = false;
      state.message = null;
      state.done = false;
    },
    resetUser: (state) => {
      state.loggedInUser = null;
      state.loginResponse = null;
      state.currentUser = null;
      state.error = false;
      state.loading = false;
    },
    setUser: (state, action) => {
      state.currentUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logoutUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.currentUser = null;
        state.loginResponse = null;
        state.message = action?.payload?.message
          ? action?.payload?.message
          : action?.payload?.data;
        state.error = false;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.message = action?.payload?.message
          ? action.payload.message
          : action.payload?.data
          ? action.payload.data
          : null;
      })
      .addCase(setPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(setPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.setPasswordResponse = action.payload;
        state.error = false;
      })
      .addCase(setPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getCurrentUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        // Ensure the structure of action.payload matches what loginResponse expects
        state.loginResponse = { data: action.payload };
        state.currentUser = action.payload; // Optional, if you decide to keep both
        state.error = false;
      })
      .addCase(getCurrentUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.message = action.payload.message;
        state.loading = false;
        state.loginResponse = action.payload;
        state.currentUser = action.payload;
        state.done = true;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.message = action.payload?.message
          ? action.payload.message
          : action.payload.data;
        state.loginResponse = action.payload;
        state.currentUser = action.payload;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.forgetPasswordResponse = action.payload;
        state.message = action.payload.message;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.forgetPasswordResponse = action.payload;
        state.message = action?.payload?.message
          ? action.payload.message
          : action.payload?.data
          ? action.payload.data
          : null;
      })
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.resetPasswordResponse = action.payload;
        state.message = action.payload.message;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.resetPasswordResponse = action.payload;
        state.message = action?.payload?.message
          ? action.payload.message
          : action.payload?.data
          ? action.payload.data
          : null;
      });
  },
});

export const { resetUser, setUser, resetGenericStates } = authSlice.actions;
export default authSlice.reducer;

