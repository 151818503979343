import { Navigate, Route, Routes } from 'react-router-dom';
import Login from '../Pages/Login/Login';
import UserRegistrationForm from '../Pages/UserRegistrationForm/UserRegistrationForm';
import ForgetPassword from '../Pages/ForgetPassword/ForgetPassword';
import ResetPassword from '../Pages/ResetPassword/ResetPassword';
import EmployeeDetails from '../Pages/EmployeeDetails/EmployeeDetails';
import MyProfile from '../Pages/MyProfile/MyProfile';
import EmployeeList from '../Pages/EmployeeList/EmployeeList';
import EmployeeTable from '../Pages/EmployeeTable/EmployeeTable';
import LeaveRequests from '../Pages/LeaveManagement/LeaveRequests';
import ReviewLeave from '../Pages/LeaveManagement/ReviewLeave';
import LeaveRecords from '../Pages/LeaveManagement/LeaveRecords';
import SubmitLeaveForm from '../Pages/LeaveManagement/SubmitLeaveForm';
import EmployeLeaveRecord from '../Pages/LeaveManagement/EmployeLeaveRecord';
import ViewAttendace from '../Pages/AttendanceManagement/ViewAttendance';
import AttendanceDetails from '../Pages/AttendanceManagement/AttendanceDetails';
import HomePage from '../Components/HomePage';
import ProjectList from '../Pages/ProjectManagement/ProjectList';
import ProjectDetail from '../Pages/ProjectManagement/ProjectDetail';
import AddUpdateProject from '../Pages/ProjectManagement/AddUpdateProject';
import LeaveDashboard from '../Pages/LeaveManagement/LeaveDashboard';
import AttendanceDashboard from '../Pages/AttendanceManagement/AttendanceDashboard';
import EmployeeAttendance from '../Pages/AttendanceManagement/EmployeeAttendance';
import ErrorPage from '../Pages/ErrorPage';
import * as RC from './route_constants';
import WorkLogHistory from '../Pages/WorkLogs/WorkLogs';
import { useSelector } from 'react-redux';
import { PrivateRouteWrapper } from './PrivateRouteWrapper';
import { PublicRouteWrapper } from './PublicRouteWrapper';
import SetPassword from '../Pages/SetPassword/SetPassword';

export const routesWithComponents = [
  { publicRoute: true, path: RC.BASEURL, component: Login },
  { publicRoute: true, path: RC.RESET_PASSWORD, component: ResetPassword },
  { publicRoute: true, path: RC.FORGOT_PASSWORD, component: ForgetPassword },
  { publicRoute: true, path: RC.SET_PASSWORD, component: SetPassword },
  { path: RC.DASHBOARD, component: HomePage },
  { path: `${RC.DASHBOARD}/:tab`, component: HomePage },
  { path: RC.MY_PROFILE, component: MyProfile },

  {
    path: RC.EMPLOYEE_LIST,
    component: EmployeeList,
    accessTo: { admin: true },
  },
  {
    path: RC.EMPLOYEE_ADD,
    component: UserRegistrationForm,
    accessTo: { admin: true },
  },
  {
    path: RC.EMPLOYEE_TABLE,
    component: EmployeeTable,
    accessTo: { admin: true },
  },
  {
    path: `${RC.EMPLOYEE_DETAILS}/:_id`,
    component: EmployeeDetails,
    accessTo: { admin: true },
  },
  { path: RC.EMPLOYEE_ATTENDANCE_MANAGEMENT, component: AttendanceDashboard },
  {
    path: `${RC.EMPLOYEE_VIEW_ATTENDANCE}/:_id/:firstName/:lastName`,
    component: ViewAttendace,
    accessTo: { admin: true },
  },

  // ATTENDANCE_DETAILS:

  { path: RC.EMPLOYEE_ATTENDANCE, component: EmployeeAttendance },

  {
    path: RC.ATTENDANCE_DETAILS,
    component: AttendanceDetails,
    accessTo: { admin: true },
  },
  { path: RC.EMPLOYEE_LEAVE_MANAGEMENT, component: LeaveDashboard },
  { path: RC.EMPLOYEE_LEAVE_REQUESTS, component: LeaveRequests },
  {
    path: `${RC.EMPLOYEE_REVIEW_LEAVE}/:id`,
    component: ReviewLeave,
    accessTo: { admin: true },
  },
  {
    path: RC.EMPLOYEE_SUBMIT_LEAVE,
    component: SubmitLeaveForm,
    requiredRole: 'Employee',
  },
  {
    path: RC.EMPLOYEE_VIEW_LEAVES,
    component: LeaveRecords,
  },
  {
    path: `${RC.EMPLOYEE_LEAVE_RECORD}/:id`,
    component: EmployeLeaveRecord,
  },
  {
    path: RC.PROJECT_BASE_URL,
    component: ProjectList,
  },
  {
    path: `${RC.PROJECT_DETAILS}/:id`,
    component: ProjectDetail,
  },
  {
    path: `${RC.EDIT_PROJECT}/:id`,
    component: AddUpdateProject,
    accessTo: { admin: true },
  },
  {
    path: RC.ADD_PROJECT,
    component: AddUpdateProject,
    accessTo: { admin: true },
  },
  {
    path: RC.WORK_LOG_HISTORY,
    component: WorkLogHistory,
  },
  {
    path: '*',
    component: ErrorPage,
    noWrapper: true,
  },
];

const AllRoutes = () => {
  const userRole = useSelector((state) =>
    state.auth.currentUser?.data?.organizationData?.role?.toLowerCase()
  );
  const isAdminLoggedIn = userRole === 'admin';

  return (
    <Routes>
      {routesWithComponents
        .filter((item) =>
          item.accessTo ? (isAdminLoggedIn ? item.accessTo.admin : false) : item
        )
        .map((item) => {
          const ComponentWrapper = item.publicRoute
            ? PublicRouteWrapper
            : PrivateRouteWrapper;

          // const props = {userRole, nowRapper}
          return (
            <Route
              key={item.path}
              path={item.path}
              element={
                <ComponentWrapper
                  userRole={userRole}
                  noWrapper={item.noWrapper}
                >
                  <item.component />
                </ComponentWrapper>
              }
            />
          );
        })}
    </Routes>
  );
};
export default AllRoutes;
