import {
  faClipboardUser,
  faPeoplePulling,
  faHandshake,
  faSackDollar,
  faPersonWalkingLuggage,
  faEnvelopeOpenText,
  faAddressBook,
  faMoneyCheckDollar,
  faFileContract,
  faBookOpenReader,
  faUserCheck,
  faDatabase,
  faUser,
  faPersonCircleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Badge,
  ContentPasteSearchOutlined,
  DashboardOutlined,
  FactCheckOutlined,
  FolderSharedOutlined,
  ManageAccountsOutlined,
  PendingActionsOutlined,
  PermContactCalendarOutlined,
  SdStorageOutlined,
  WebStoriesOutlined,
} from '@mui/icons-material';
import { WORK_LOG_HISTORY } from '../Routes/route_constants';
import * as RC from '../Routes/route_constants';

export const REIMBURSEMENT_ENUMS = [
  { value: 'Medical Expenses', label: 'Medical Expenses' },
  { value: 'Travel Expenses', label: 'Travel Expenses' },
  { value: 'Gym Allowance', label: 'Gym Allowance' },
  { value: 'Maternity Allowance', label: 'Maternity Allowance' },
];

export const USER_ROLE_ENUMS = ['Admin', 'Employee'];
export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const EMPLOYEE_TYPE_ENUMS = ['Hiring Manager', 'Manager', 'Regular'];

export const CURRENCY_ENUMS = ['PKR', 'USD', 'EUR'];

export const EMPLOYEE_REIMBURSEMENT_ENUMS = [
  {
    name: 'Request Reimbursement',
    linkTo: RC.REIMBURSEMENT_REQUEST_FORM,
  },
  {
    name: 'View Past Requests',
    linkTo: RC.EMPLOYEE_REIMBURSEMENT_PAST_REQUESTS,
  },
  {
    name: 'Pending Requests',
    linkTo: RC.EMPLOYEE_REIMBURSEMENT_PENDING_REQUESTS,
  },
];

export const CURRENT_EMPLOYEMENT_STATUS = [
  'Full-time',
  'Part-time',
  'Contractor',
  'Intern',
  'Freelancer',
  'Consultant',
  'On notice',
  'Ex employee',
];

export const PROJECT_STATUS_ENUMS = [
  'Active',
  'Inactive',
  'On Pause',
  'Completed',
  'Pending',
];

export const COMPANY_RESOURCE_ENUM = {
  ADD_COMPANY_RESOURCE: {
    label: 'Add Company Resource',
    icon: <Badge />,
    url: RC.RESOURCES_LIST,
  },
};

export const LEAVE_DASHBOARD_ENUMS = {
  PENDING_LEAVE_REQUEST: {
    label: 'Pending Requests',
    icon: <FontAwesomeIcon icon={faPersonCircleExclamation} />,
    url: RC.EMPLOYEE_LEAVE_REQUESTS,
  },
  LEAVE_RECORDS: {
    label: 'Leave Records',
    icon: <FontAwesomeIcon icon={faDatabase} />,
    url: RC.EMPLOYEE_VIEW_LEAVES,
  },
};
export const PROJECT_STATUS_OBJ_ENUMS = [
  { value: 'Active', label: 'Active' },
  { value: 'Inactive', label: 'Inactive' },
  { value: 'On Pause', label: 'On Pause' },
  { value: 'Completed', label: 'Completed' },
  { value: 'Pending', label: 'Pending' },
];

export const PROJECT_MEMBERS_ENUMS = [
  'Project Manager',
  'Technical Lead',
  'Engineer',
];

export const ADMIN_SIDEBAR = {
  DASHBOARD: {
    label: 'Dashboard',
    icon: <DashboardOutlined />,
    // icon: <FontAwesomeIcon icon={faPeoplePulling} />,
    url: '/dashboard',
  },
  EMPLOYEE_RECORDS: {
    label: 'Employee Directory',
    icon: <FolderSharedOutlined />,
    // icon: <FontAwesomeIcon icon={faBookOpenReader} />,
    url: RC.EMPLOYEE_LIST,
  },
  ATTENDANCE_MANAGEMENT: {
    label: 'Attendance Management',
    icon: <PermContactCalendarOutlined />,
    // icon: <FontAwesomeIcon icon={faClipboardUser} />,
    url: RC.EMPLOYEE_ATTENDANCE_MANAGEMENT,
  },

  PENDING_LEAVE_REQUEST: {
    // Only for admins and HR
    label: 'Pending Leave Requests',
    icon: <PendingActionsOutlined />,
    // icon: <FontAwesomeIcon icon={faPersonCircleExclamation} />,
    url: RC.EMPLOYEE_LEAVE_REQUESTS,
  },
  LEAVE_RECORDS: {
    label: 'Leave Records',
    icon: <ContentPasteSearchOutlined />,
    // icon: <FontAwesomeIcon icon={faPersonWalkingLuggage} />,
    url: RC.EMPLOYEE_VIEW_LEAVES,
  },
  PROJECTS: {
    label: 'Projects',
    icon: <WebStoriesOutlined />,
    // icon: <FontAwesomeIcon icon={faFileContract} />,
    url: RC.PROJECT_BASE_URL,
  },
  WORK_LOG_HISTORY: {
    label: 'Work Log History',
    icon: <SdStorageOutlined />,
    // icon: <FontAwesomeIcon icon={faDatabase} />,
    url: RC.WORK_LOG_HISTORY,
  },
  MY_PROFILE: {
    label: 'My Profile',
    icon: <ManageAccountsOutlined />,
    // icon: <FontAwesomeIcon icon={faUser} />,
    url: RC.MY_PROFILE,
  },
};

export const EMPLOYEE_SIDEBAR = {
  DASHBOARD: {
    label: 'Dashboard',
    // icon: <FontAwesomeIcon icon={faPeoplePulling} />,
    icon: <DashboardOutlined />,
    url: '/dashboard',
  },
  PROJECTS: {
    label: 'Projects',
    // icon: <FontAwesomeIcon icon={faFileContract} />,
    icon: <WebStoriesOutlined />,
    url: RC.PROJECT_BASE_URL,
  },
  WORK_LOG_HISTORY: {
    label: 'Work Log History',
    // icon: <FontAwesomeIcon icon={faDatabase} />, // Choose an appropriate icon
    icon: <SdStorageOutlined />,
    url: WORK_LOG_HISTORY,
  },
  LEAVE_RECORDS: {
    label: 'Leave Records',
    // icon: <FontAwesomeIcon icon={faPersonWalkingLuggage} />,
    icon: <ContentPasteSearchOutlined />,
    url: RC.EMPLOYEE_VIEW_LEAVES,
  },
  EMPLOYEE_ATTENDANCE: {
    label: 'Attendance Records',
    icon: <FactCheckOutlined />,
    // icon: <FontAwesomeIcon icon={faClipboardUser} />,
    url: RC.EMPLOYEE_ATTENDANCE,
  },
  MY_PROFILE: {
    label: 'My Profile',
    // icon: <FontAwesomeIcon icon={faUser} />,
    icon: <ManageAccountsOutlined />,
    url: RC.MY_PROFILE,
  },
};

export const ADMIN_VIEW_CARDS = [
  {
    name: 'Leave Management',
    linkTo: '/leavedashboard',
    icons: faPersonWalkingLuggage,
  },

  {
    name: 'Attendance Management',
    linkTo: '/attendance-dashboard',
    icons: faClipboardUser,
  },
  {
    name: 'Employee Records',
    linkTo: '/employeelist',
    icons: faBookOpenReader,
  },
  {
    name: 'Employee onboarding',
    linkTo: '',
    icons: faHandshake,
  },
  {
    name: 'Reimbursement Management',
    linkTo: '/reimbursementdashboard',
    icons: faSackDollar,
  },
  {
    name: 'Performance Management',
    linkTo: RC.EMPLOYEE_PERFORMANCE_MANAGEMENT,
    icons: faEnvelopeOpenText,
  },
  {
    name: 'Employee Handbook',
    linkTo: '/employeehandbook',
    icons: faAddressBook,
  },
  {
    name: 'Employee Offboarding',
    linkTo: '/offboardingdashboard',
    icons: faPeoplePulling,
  },
  {
    name: 'Payroll Management',
    linkTo: '',
    icons: faMoneyCheckDollar,
  },
];

export const EMPLOYEE_VIEW_CARDS = [
  {
    name: 'Leave Management',
    linkTo: '/leavedashboard',
    icons: faPersonWalkingLuggage,
  },
  {
    name: 'Employee Handbook',
    linkTo: '/employeehandbook',
    icons: faAddressBook,
  },
  {
    name: 'Projects',
    linkTo: '/projects',
    icons: faFileContract,
  },
  {
    name: 'Performance Appraisals',
    linkTo: RC.EMPLOYEE_PERFORMANCE_MANAGEMENT,
    icons: faEnvelopeOpenText,
  },
  {
    name: 'Reimbursements',
    linkTo: '/reimbursementdashboard',
    icons: faSackDollar,
  },
  {
    name: 'Attendance Record',
    linkTo: RC.EMPLOYEE_ATTENDANCE,
    icons: faUserCheck,
  },
];
export const LEAVE_VIEW_CARDS = [
  {
    name: 'Leave Management',
    linkTo: RC.EMPLOYEE_LEAVE_REQUESTS,
  },
  {
    name: 'Leave Records',
    linkTo: RC.EMPLOYEE_VIEW_LEAVES,
  },
];
export const DRAWER_WIDTH = 285;

export const LEAVE_VIEW_EMPLOYEE_CARDS = [
  {
    name: 'Request Leave',
    linkTo: '/submit-leave',
  },
  {
    name: 'Leave Records',
    linkTo: `/employee-leave-record/${
      JSON.parse(localStorage.getItem('user'))?._id
    }`,
  },
];

export const LEAVE_STATUS_ENUMS = ['Pending', 'Approved', 'Rejected'];
export const LEAVE_TYPE_NAMES_ENUMS = [
  'Casual Leave',
  'Sick Leave',
  'Annual Leave',
];

export const LEAVE_TYPES_ENUMS = [
  { type: 'Sick Leaves', default_count: 12 },
  { type: 'Casual Leaves', default_count: 10 },
  { type: 'Annual Leaves', default_count: 12 },
];
export const REQUEST_STATUS_ENUMS = [
  'Pending',
  'Accepted',
  'Refused',
  'Cancelled',
];
export const YEARS = [
  '2016',
  '2017',
  '2018',
  '2019',
  '2020',
  '2021',
  '2022',
  '2023',
  '2024',
];
