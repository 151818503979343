import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  forgotPassword,
  resetGenericStates,
} from '../../Redux/slices/authSlice';
import { toast } from 'react-toastify';
import { Button, TextField, Typography } from '@mui/material';
import UnAuthPageLayout from '../../Components/PageLayout/UnAuthPageLayout';

function ForgotPassword() {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.auth.error);
  const message = useSelector((state) => state.auth.message);
  // const done = useSelector((state) => state.auth.done);

  const initialValues = { emailAddress: '' };

  const validationSchema = Yup.object({
    emailAddress: Yup.string()
      .email('Invalid email')
      .max(255, 'Email cannot exceed 255 characters')
      .required('Required'),
  });

  const handleSubmit = (values, { resetForm }) => {
    dispatch(forgotPassword(values.emailAddress))
      .unwrap()
      .then(() => {
        toast.success(message || 'Password reset link sent to your email.');
        resetForm();
      })
      .catch((error) => {
        toast.error(message || 'Failed to send password reset link.');
        console.error(error);
      });
  };

  useEffect(() => {
    if (error) {
      toast.error(message);
      dispatch(resetGenericStates());
    }
  }, [error, message, dispatch]);

  return (
    <UnAuthPageLayout>
      <Typography variant="h5" textAlign="left" fontWeight={500} mb="8px">
        Forgot Password?
      </Typography>
      <Typography textAlign="left" variant="body2" mb="28px">
        Enter your email address below, and we'll email you instructions for
        setting a new one.
      </Typography>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleChange, handleBlur, values }) => (
          <Form>
            <TextField
              required
              fullWidth
              id="emailAddress"
              label="Email Address"
              name="emailAddress"
              autoComplete="email"
              autoFocus
              value={values.emailAddress}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
              error={error && Boolean(message)}
              helperText={error ? message : ''}
              style={{ margin: 0, height: '70px' }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mb: '42px', mt: '12px' }}
            >
              Send Reset Link
            </Button>
          </Form>
        )}
      </Formik>
    </UnAuthPageLayout>
  );
}

export default ForgotPassword;
