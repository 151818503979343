import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllUsers } from '../../Redux/slices/userSlice';
import CustomTable from '../../Components/MiscComponents/CustomTable';
import UserRegistrationForm from '../UserRegistrationForm/UserRegistrationForm';
import { EMPLOYEE_DETAILS } from '../../Routes/route_constants';
import ContentWrapper from '../../Components/MiscComponents/ContentWrapper';

const EmployeeList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.users.loading);
  const userResponse = useSelector((state) => state.users.allUsers);
  const metadata = useSelector((state) => state.users.metadata); // Fetch metadata from Redux state

  const headers = [
    { label: 'Employee Code', key: 'organizationData.employee_code' },
    { label: 'Full Name', keys: ['first_name', 'last_name'] },
    { label: 'Email', key: 'email' },
    { label: 'Job Title', key: 'organizationData.job_title' },
    { label: 'Role', key: 'organizationData.role' },
  ];

  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1); // For pagination control
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [statusFilter, setStatusFilter] = useState('');
  // const [roleFilter, setRoleFilter] = useState('');

  useEffect(() => {
    // Fetch users based on current page
    dispatch(
      getAllUsers({
        filters: {
          search: search || undefined,
          // roleFilter: roleFilter || '',
          // statusFilter: statusFilter || '',
        },
        page: currentPage,
      })
    );
  }, [dispatch, currentPage, search]);

  const allUsers = userResponse.map((user) => ({
    ...user,
    full_name: `${user.first_name} ${user.last_name}`, // Creating a 'full_name' field for display
  }));

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleClick = (employee) => {
    navigate(`${EMPLOYEE_DETAILS}/${employee.id}`);
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  // const handleStatusChange = (e) => {
  //   setStatusFilter(e.target.value);
  // };

  // const handleRoleChange = (e) => {
  //   setRoleFilter(e.target.value);
  // };

  // Pagination change handler
  const handlePageChange = (event, value) => {
    setCurrentPage(value); // Set new current page
  };

  const handleRefresh = () => {
    dispatch(
      getAllUsers({
        filters: { search: search || undefined },
        page: currentPage,
      })
    );
  };

  return (
    <>
      <ContentWrapper
        heading={'Employee Directory'}
        isLoading={loading}
        addButtonOnClick={handleModalOpen}
        addButtonText="Add Employee"
        filters={[
          {
            type: 'input',
            placeholder: 'Search...',
            value: search,
            onChange: handleSearch,
          },
        ]}
      >
        <CustomTable
          headers={headers}
          data={allUsers}
          handleRowClick={handleClick}
          totalPages={metadata.totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          emptyStateMessage='No employees available'
        />
      </ContentWrapper>

      <UserRegistrationForm
        open={isModalOpen}
        onClose={handleModalClose}
        onUserCreated={handleRefresh}
      />
    </>
  );
};

export default EmployeeList;
