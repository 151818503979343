import { Box, Typography } from '@mui/material';
import Colors from '../../Utils/Colors';
import placeholderImage from '../../Assets/images/no-data.png';
import { borderRadius } from '../../Utils/LayoutSpacing';

const PlaceholderForEmptyState = ({ message }) => {
  return (
    <Box
      p="60px 20px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      borderRadius={`${borderRadius.xl}px`}
      border={`2px dashed ${Colors.border}`}
    >
      <Box
        component="img"
        src={placeholderImage}
        width={{ xs: '180px', sm: '220px' }}
        maxWidth={'100%'}
      />
      <Typography textAlign="center" color={Colors.textMid}>
        {message || 'No data available'}
      </Typography>
    </Box>
  );
};

export default PlaceholderForEmptyState;
