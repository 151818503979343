import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  allProjects: [],
  selectedProject: null,
  projectDetail: null,
  employeeProjects: [],
  loading: false,
  done: false,
  error: false,
  message: null,
  projectsFetched: false,
};

export const BASE_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

export const getAllProjects = createAsyncThunk(
  'projects/getAll',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get('/v1/projects', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.toString());
    }
  }
);

export const getProjectByID = createAsyncThunk(
  'projects/getProjectByID',
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`/v1/projects/${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.toString());
    }
  }
);

export const getProjectByEmployeeID = createAsyncThunk(
  'projects/getProjectbyEmployeeID',
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(
        `/projects/get-project-by-employeeID?_id=${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addNewProject = createAsyncThunk(
  'projects/add',
  async (data, thunkAPI) => {
    try {
      const response = await axios.post('/v1/projects', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.toString());
    }
  }
);

export const updateProject = createAsyncThunk(
  'projects/updateProject',
  async ({ id, ...data }, thunkAPI) => {
    try {
      const response = await axios.put(`/v1/projects/${id}`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.toString());
    }
  }
);

export const deleteProject = createAsyncThunk(
  'projects/deleteProject',
  async (id, thunkAPI) => {
    try {
      const response = await axios.delete(`/v1/projects/${id}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.toString());
    }
  }
);

export const projectSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    resetGenericStates: (state, action) => {
      state.message = null;
    },
    updateSelectedProject: (state, action) => {
      state.selectedProject = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllProjects.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllProjects.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.allProjects = action.payload?.data;
        state.projectsFetched = true;
      })
      .addCase(getAllProjects.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.data;
        state.allProjects = [];
        state.projectsFetched = true;
      })
      .addCase(getProjectByID.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProjectByID.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.projectDetail = action.payload?.data;
      })
      .addCase(getProjectByID.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.data;
        state.projectDetail = [];
      })
      .addCase(getProjectByEmployeeID.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProjectByEmployeeID.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.employeeProjects = action.payload?.data;
      })
      .addCase(getProjectByEmployeeID.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.data;
        state.employeeProjects = [];
      })
      .addCase(addNewProject.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewProject.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.message = action.payload.message;
        state.allProjects = [...state.allProjects, action.payload?.data];
      })
      .addCase(addNewProject.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.message = action.payload?.message
          ? action.payload.message
          : action.payload?.data
            ? action.payload.data
            : null;
      })

      .addCase(updateProject.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProject.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload.message;
        state.done = true;
        state.allProjects = state.allProjects.map((project) => {
          return project.id === action.payload?.data?.id
            ? { ...project, ...action.payload?.data }
            : project;
        });
      })

      .addCase(updateProject.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.allProjects = [];
        state.message = action.payload?.message
          ? action.payload.message
          : action.payload?.data
            ? action.payload.data
            : null;
      })

      .addCase(deleteProject.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.message = action.payload?.message;
        state.allProjects = state.allProjects.filter((item) => {
          return item._id !== action.payload?.data?._id;
        });
      })
      .addCase(deleteProject.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.data;
        state.message = action.payload?.message
          ? action.payload.message
          : action.payload?.data
            ? action.payload.data
            : null;
      });
  },
});

export const { updateSelectedProject, resetGenericStates } =
  projectSlice.actions;
export default projectSlice.reducer;
