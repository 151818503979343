import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProjects } from '../../Redux/slices/projectSlice';
import { createMultipleWorkLogs } from '../../Redux/slices/workLogsSlice';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Grid,
  Typography,
  Container,
  IconButton,
  Box,
  Card,
} from '@mui/material';
import { toast } from 'react-toastify';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { getAllWorkLogs } from '../../Redux/slices/workLogsSlice';
import Colors from '../../Utils/Colors';
import CustomButton from '../MiscComponents/CustomButton';
import { DeleteOutlined } from '@mui/icons-material';

const AppRocketWorkingLog = ({ onClose }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.currentUser?.data);
  const projectsResponse = useSelector((state) => state.projects.allProjects);
  const projectsFetched = useSelector(
    (state) => state.projects.projectsFetched
  );

  // State to handle multiple work logs
  const [workLogs, setWorkLogs] = useState([
    {
      project: '',
      description: '',
      date: new Date().toISOString().split('T')[0],
      duration: '',
    },
  ]);

  useEffect(() => {
    if (!projectsFetched) {
      dispatch(getAllProjects());
    }
  }, [dispatch, projectsFetched]);

  const handleInputChange = (index, e) => {
    const newWorkLogs = [...workLogs];
    newWorkLogs[index][e.target.name] = e.target.value;
    setWorkLogs(newWorkLogs);
  };

  const handleAddWorkLog = () => {
    const todayDate = new Date().toISOString().split('T')[0];
    setWorkLogs([
      ...workLogs,
      { project: '', description: '', date: todayDate, duration: '' },
    ]);
  };

  const handleRemoveWorkLog = (index) => {
    const newWorkLogs = [...workLogs];
    newWorkLogs.splice(index, 1);
    setWorkLogs(newWorkLogs);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (user && user.id) {
      await dispatch(
        createMultipleWorkLogs({
          userId: user.id,
          workLogsData: workLogs,
        })
      )
        .unwrap()
        .then(() => {
          toast.success('Work logs created successfully.');
          dispatch(getAllWorkLogs({ userId: user.id }));
          onClose && onClose();
        })
        .catch((error) => {
          toast.error(
            `Error: ${error.message || 'Could not create work logs.'}`
          );
        });
    }
  };

  return (
    <Container
      component="form"
      onSubmit={handleFormSubmit}
      sx={{ mt: 3, padding: '0px !important' }}
    >
      <Grid container spacing={3} sx={{ borderRadius: 3 }}>
        {workLogs.map((workLog, index) => (
          <Grid item xs={12} key={index}>
            <Card className="no-hover" variant="outlined" sx={{ p: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth sx={{ mb: 1 }}>
                    <InputLabel>Project</InputLabel>
                    <Select
                      name="project"
                      value={workLog.project}
                      label="Project"
                      onChange={(e) => handleInputChange(index, e)}
                    >
                      {projectsResponse.map((project) => (
                        <MenuItem key={project.id} value={project.id}>
                          {project.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Description"
                    name="description"
                    value={workLog.description}
                    onChange={(e) => handleInputChange(index, e)}
                    sx={{ mb: 1 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    type="date"
                    label="Date"
                    InputLabelProps={{ shrink: true }}
                    name="date"
                    value={workLog.date}
                    onChange={(e) => handleInputChange(index, e)}
                    sx={{ mb: 1 }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Duration (hours)"
                    name="duration"
                    value={workLog.duration}
                    onChange={(e) => handleInputChange(index, e)}
                    sx={{ mb: 1 }}
                  />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="flex-end">
                  <IconButton
                    onClick={() => handleRemoveWorkLog(index)}
                    sx={{ color: 'error.main' }}
                  >
                    <DeleteOutlined />
                  </IconButton>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
        <Grid item xs={12} display="flex" alignItems="center">
          <Box
            onClick={handleAddWorkLog}
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              color: Colors.link,
            }}
          >
            <AddBoxIcon />
            <Typography variant="subtitle1" sx={{ ml: 1 }}>
              Add another task
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}
        >
          <CustomButton variant="contained" color="primary" type="submit">
            Submit
          </CustomButton>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AppRocketWorkingLog;
