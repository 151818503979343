import { Box, Typography, keyframes } from '@mui/material';
import Colors from '../../Utils/Colors';
import LayoutSpacing from '../../Utils/LayoutSpacing';

const movingForward = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const CustomMarquee = ({ text = 'Customer', sx }) => {
  return (
    <Box
      className="text-wrapper"
      sx={{
        ...sx,
        position: 'absolute',
        zIndex: -1,
        display: 'flex',
        justifyContent: 'flex-start',
        overflow: 'hidden',
        maxWidth: {
          xs: `calc(100% + ${LayoutSpacing.pagePaddingSm} + ${LayoutSpacing.pagePaddingSm})`,
          sm: `calc(100% + ${LayoutSpacing.pagePadding} + ${LayoutSpacing.pagePadding})`,
        },
        width: {
          xs: `calc(100% + ${LayoutSpacing.pagePaddingSm} + ${LayoutSpacing.pagePaddingSm})`,
          sm: `calc(100% + ${LayoutSpacing.pagePadding} + ${LayoutSpacing.pagePadding})`,
        },
        marginLeft: {
          xs: `-${LayoutSpacing.pagePaddingSm}`,
          sm: `-${LayoutSpacing.pagePadding}`,
        },
        top: '-50px',

        '& h2': {
          whiteSpace: 'nowrap',
          paddingInline: '4rem',
          letterSpacing: '0px',
          margin: 0,
          animation: `${movingForward} ${
            text?.length > 15 ? 85.5 : 25.5
          }s linear infinite`,
          color: 'white',
          textShadow: `1px 1px 1px ${Colors.primaryBg}, -1px 1px 1px ${Colors.primaryBg}, -1px -1px 0 ${Colors.primaryBg}, 1px -1px 0 ${Colors.primaryBg}`,
          fontSize: { xs: '180px', sm: '220px', md: '350px' },
          lineHeight: { xs: '220px', sm: '265px', md: '414px' },
          fontWeight: 600,
        },
      }}
    >
      <Typography component="h2">{text}</Typography>
      <Typography component="h2">{text}</Typography>
      <Typography component="h2">{text}</Typography>
      <Typography component="h2">{text}</Typography>
      <Typography component="h2">{text}</Typography>
      <Typography component="h2">{text}</Typography>
      <Typography component="h2">{text}</Typography>
      <Typography component="h2">{text}</Typography>
      <Typography component="h2">{text}</Typography>
    </Box>
  );
};

export default CustomMarquee;
