import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  dailyAttendance: [],
  userAttendance: [],
  attendanceStatus: null,
  loading: false,
  error: null,
  message: null,
  metadata: {
    totalResults: 0,
    totalPages: 0,
    currentPage: 1,
    limit: 10,
  },
};

// Base URL for API requests
export const BASE_URL = process.env.REACT_APP_API_URL;

// Axios configuration
axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

export const getDailyAttendance = createAsyncThunk(
  'attendance/getDailyAttendance',
  async ({ filters = {}, page = 1, statusFilter = '', limit = 10, sort = '-createdAt' }, { rejectWithValue }) => {
    try {

      const params = { ...filters, page, limit, sort };
      if (statusFilter) {
        params.statusFilter = statusFilter;
      }
      const response = await axios.get(`/v1/attendance`, { params });

      return response.data;
    } catch (error) {
     
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {

        return rejectWithValue({ message: "An unexpected error occurred." });
      }
    }
  }
);


// Thunk for fetching attendance status
export const getAttendanceStatus = createAsyncThunk(
  'attendance/getAttendanceStatus',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/v1/attendance/status');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAttendanceStatus = createAsyncThunk(
  'attendance/updateAttendanceStatus',
  async ({ status, timestamp }, { rejectWithValue }) => {
      try {
          const response = await axios.post('/v1/attendance/status', {
              status,
              timestamp // Send timestamp along with the status
          });
          return response.data;
      } catch (error) {
          return rejectWithValue(error.response.data);
      }
  }
);
export const getUserAttendance = createAsyncThunk(
  'attendance/getUserAttendance',
  async ({ userId, startDate, endDate, page = 1, limit = 10 }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/v1/users/${userId}/attendance`, {
        params: { filter_start: startDate, filter_end: endDate, page, limit },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// Attendance slice
const attendanceSlice = createSlice({
  name: 'attendance',
  initialState,
  reducers: {
    resetAttendanceState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
    .addCase(getDailyAttendance.pending, (state) => {
      state.loading = true;
    })
    .addCase(getDailyAttendance.fulfilled, (state, action) => {
      state.loading = false;
      state.dailyAttendance = action.payload.data;
      state.metadata = action.payload.metadata; // Updating the metadata state
      state.error = null;
    })
    .addCase(getDailyAttendance.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    })
      .addCase(getAttendanceStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAttendanceStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.attendanceStatus = action.payload.status;
        state.error = null;
      })
      .addCase(getAttendanceStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateAttendanceStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAttendanceStatus.fulfilled, (state) => {
        state.loading = false;
        state.message = 'Attendance status updated successfully';
        state.error = null;
      })
      .addCase(updateAttendanceStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getUserAttendance.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserAttendance.fulfilled, (state, action) => {
        state.loading = false;
        state.userAttendance = action.payload.data;
        state.metadata = action.payload.metadata;
        state.error = null;
      })
      .addCase(getUserAttendance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetAttendanceState } = attendanceSlice.actions;
export default attendanceSlice.reducer;
