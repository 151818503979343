import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  scheduledAppraisals: [],
  selectedAppraisal: null,
  upcomingAppraisals: null,
  appraisalDetail: null,
  pastAppraisals: null,
  specificPast: null,
  specificComing: null,
  loading: false,
  done: false,
  error: false,
};

export const BASE_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

export const getPastAppraisal = createAsyncThunk(
  'getPastAppraisal',
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(
        `/appraisal-scheduling/previous-appraisals?page=${params.page}&limit=${params.limit}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      return response?.data;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getUpcomingAppraisal = createAsyncThunk(
  'getUpcomingAppraisal',
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(
        `/appraisal-scheduling/upcoming-appraisals?page=${params.page}&limit=${params.limit}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      return response?.data;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPastAppraisalByEmployeeID = createAsyncThunk(
  'getPastAppraisalByEmployeeID',
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(
        `/appraisal-scheduling/past-specific-appraisal?_id=${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      return response?.data;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getUpcomingAppraisalByEmployeeID = createAsyncThunk(
  ' getUpcomingAppraisalByEmployeeID',
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(
        `/appraisal-scheduling/upcoming-specific-appraisal?_id=${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      return response?.data;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getEmployeeAppraisal = createAsyncThunk(
  'getEmployeeAppraisal',
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(
        `/appraisal-scheduling/get-appraisal-by-userID?_id=${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      return response?.data;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAppraisalByID = createAsyncThunk(
  'getAppraisalByID',
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(
        `/appraisal-scheduling/get-appraisal-by-appraisalID?_id=${id}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      return response?.data;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const scheduleNewAppraisal = createAsyncThunk(
  'scheduleNewAppraisal',
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        `/appraisal-scheduling/add-appraisal`,
        data,
        {
          headers: {
            'Content-type': 'application/json',
          },
        }
      );

      return response?.data;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateAppraisal = createAsyncThunk(
  'updateAppraisal',
  async (data, thunkAPI) => {
    try {
      const response = await axios.patch(
        '/appraisal-scheduling/update-appraisal',
        data,
        {
          headers: {
            'Content-type': 'application/json',
          },
        }
      );

      return response?.data;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteAppraisal = createAsyncThunk(
  'deleteAppraisal',
  async (id, thunkAPI) => {
    try {
      const response = await axios.delete(``, {
        headers: {
          'Content-type': 'application/json',
        },
      });
      return response?.data?.data;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const appraisalSlice = createSlice({
  name: 'appraisals',
  initialState,
  reducers: {
    resetAllTabStates: (state, action) => {
      state.appraisalDetail = null;
      state.pastAppraisals = null;
      state.upcomingAppraisals = null;
      state.specificComing = null;
      state.specificPast = null;
    },
    resetOtherTabStates: (state, action) => {
      switch (action.payload) {
        case 0: // Upcoming Appraisals
          state.pastAppraisals = null;
          state.appraisalDetail = null;
          state.specificPast = null;
          return;
        case 1: // Past Appraisals
          state.upcomingAppraisals = null;
          state.appraisalDetail = null;
          state.specificComing = null;
          return;
        // case 2: // Employee Appraisals
        //   state.upcomingAppraisals = null;
        //   state.appraisalDetail = null;
        //   return;
        default:
          return;
      }
    },
    updateSelectedAppraisal: (state, action) => {
      state.selectedAppraisal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPastAppraisal.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPastAppraisal.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.pastAppraisals = action.payload?.data;
      })
      .addCase(getPastAppraisal.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.data;
        state.pastAppraisals = [];
      })
      .addCase(getUpcomingAppraisal.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUpcomingAppraisal.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.upcomingAppraisals = action.payload?.data;
      })
      .addCase(getUpcomingAppraisal.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.upcomingAppraisals = [];
      })
      .addCase(getEmployeeAppraisal.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEmployeeAppraisal.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.appraisalDetail = action.payload?.data;
      })
      .addCase(getEmployeeAppraisal.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.appraisalDetail = [];
      })
      .addCase(getAppraisalByID.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAppraisalByID.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.selectedAppraisal = action?.payload?.data;
      })
      .addCase(getAppraisalByID.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.selectedAppraisal = [];
      })
      .addCase(getPastAppraisalByEmployeeID.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPastAppraisalByEmployeeID.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.specificPast = action.payload?.data;
      })
      .addCase(getPastAppraisalByEmployeeID.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.specificPast = [];
      })
      .addCase(getUpcomingAppraisalByEmployeeID.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUpcomingAppraisalByEmployeeID.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.specificComing = action.payload?.data;
      })
      .addCase(getUpcomingAppraisalByEmployeeID.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.specificComing = [];
      })
      .addCase(scheduleNewAppraisal.pending, (state) => {
        state.loading = true;
      })
      .addCase(scheduleNewAppraisal.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.scheduledAppraisals = [
          ...state.scheduledAppraisals,
          action.payload?.data,
        ];
      })
      .addCase(scheduleNewAppraisal.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateAppraisal.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAppraisal.fulfilled, (state, action) => {
        state.loading = false;
        state.done = true;
        state.scheduledAppraisals = state.scheduledAppraisals.map(
          (appraisal) => {
            return appraisal._id === action.payload._id
              ? Object.assign({}, appraisal, action.payload.data)
              : appraisal;
          }
        );
      })
      .addCase(updateAppraisal.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.scheduledAppraisals = [];
      });
  },
});

export const {
  updateSelectedAppraisal,
  resetOtherTabStates,
  resetAllTabStates,
} = appraisalSlice.actions;
export default appraisalSlice.reducer;
