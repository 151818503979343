import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProjectByID,
  deleteProject,
  updateProject,
} from '../../Redux/slices/projectSlice';
import { getAllUsers } from '../../Redux/slices/userSlice';
import { Box, Typography, Chip } from '@mui/material';
import moment from 'moment';
import { PROJECT_STATUS_ENUMS } from '../../Utils/Constants';
import { useCallback } from 'react';
import Loader from '../../Components/MiscComponents/Loader';
import CustomButton from '../../Components/MiscComponents/CustomButton';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import EditProjectDialog from './EditProjectDialog';
import DeleteProjectDialog from './DeleteProjectDialog';
import CustomMarquee from '../../Components/MiscComponents/CustomMarquee';
import DynamicBackButton from '../../Components/BackButton/DynamicBackButton';
import Colors from '../../Utils/Colors';
import LayoutSpacing from '../../Utils/LayoutSpacing';
import { formatDate } from '../../Utils/functions';

function ProjectDetail() {
  const { id: projectId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRole = useSelector(
    (state) => state.auth.currentUser?.data?.organizationData?.role
  );
  const { projectDetail, loading } = useSelector((state) => state.projects);
  const fetchUsersDebounced = useCallback(
    debounce((search) => fetchUsers(search), 500),
    []
  );

  const [employees, setEmployees] = useState([]);
  const [projectManagerSearch, setProjectManagerSearch] = useState('');
  const usersResponse = useSelector((state) => state.users.allUsers);
  const [memberSearch, setMemberSearch] = useState('');
  // const [allUsers, setAllUsers] = useState([]);
  // const [formValues, setFormValues] = useState({
  //   name: '',
  //   description: '',
  //   start_date: '',
  //   end_date: '',
  //   project_manager: null,
  //   members: [],
  //   status: '',
  // });
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editedProject, setEditedProject] = useState({
    name: '',
    description: '',
    start_date: '',
    end_date: '',
    project_manager: '',
    members: [],
    status: '',
  });

  function debounce(func, wait) {
    let timeout;

    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  useEffect(() => {
    fetchUsersDebounced(projectManagerSearch);
  }, [projectManagerSearch, fetchUsersDebounced]);

  useEffect(() => {
    fetchUsersDebounced(memberSearch);
  }, [memberSearch, fetchUsersDebounced]);

  useEffect(() => {
    if (projectId) {
      dispatch(getProjectByID(projectId));
    }
  }, [dispatch, projectId]);

  const fetchUsers = (search) => {
    dispatch(getAllUsers({ filters: { search: search }, page: 1 }));
  };
  useEffect(() => {
    if (projectDetail) {
      // Map projectDetail.members to the expected structure for Autocomplete
      const formattedMembers = projectDetail.members.map((member) => ({
        label: `${member.first_name} ${member.last_name}`,
        value: member.id,
      }));

      setEditedProject({
        name: projectDetail.name,
        description: projectDetail.description,
        start_date: formatDate(projectDetail.start_date),
        end_date: formatDate(projectDetail.end_date),
        project_manager: {
          label: `${projectDetail.project_manager.first_name} ${projectDetail.project_manager.last_name}`,
          value: projectDetail.project_manager.id,
        },
        members: formattedMembers,
        status: projectDetail.status,
      });
    }
  }, [projectDetail]);

  useEffect(() => {
    if (usersResponse) {
      const formattedUsers = usersResponse.map((user) => ({
        label: `${user.first_name} ${user.last_name}`,
        value: user.id,
      }));
      setEmployees(formattedUsers);
    }
  }, [usersResponse]);

  const handleEditChange = (fieldOrEvent, newValue) => {
    let field = fieldOrEvent;
    let value = newValue;

    if (fieldOrEvent.target) {
      field = fieldOrEvent.target.name;
      value = fieldOrEvent.target.value;
    }

    if (typeof fieldOrEvent === 'object' && fieldOrEvent.target) {
      // It's an event from a TextField
      const { name, value } = fieldOrEvent.target;
      setEditedProject((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      // It's from Select or Autocomplete, which requires a field name and new value
      const fieldName = fieldOrEvent; // The first argument is the field name
      setEditedProject((prev) => ({
        ...prev,
        [fieldName]: newValue,
      }));
    }
    if (field === 'start_date' || field === 'end_date') {
      const start = field === 'start_date' ? value : editedProject.start_date;
      const end = field === 'end_date' ? value : editedProject.end_date;
      setDateError(moment(end).isBefore(moment(start)));
    }
  };

  const handleSubmitEdit = () => {
    // Format dates to ISO strings
    const start_date = moment(editedProject.start_date).toISOString();
    const end_date = moment(editedProject.end_date).toISOString();

    // Ensure project_manager is submitted as an ID
    const project_manager =
      editedProject.project_manager?.value || editedProject.project_manager; // Adjust based on your state structure

    // Use a default empty array if editedProject.members is undefined to avoid errors
    const members = (editedProject.members || []).map(
      (member) => member.value || member
    ); // Ensure it's an array of IDs

    // Prepare the payload with the formatted data
    const updatePayload = {
      ...editedProject,
      start_date,
      end_date,
      project_manager,
      members,
    };

    // Dispatch the update action
    dispatch(updateProject({ id: projectId, ...updatePayload }))
      .unwrap()
      .then(() => {
        setEditMode(false);
        // Optionally, notify the user of success
        dispatch(getProjectByID(projectId));
      })
      .catch((error) => {
        console.error('Failed to update project:', error);
        // Optionally, handle the error (e.g., show an error message)
      });
  };

  // const handleChange = (name, value) => {
  //   setFormValues((prev) => ({ ...prev, [name]: value }));
  // };

  const handleDeleteConfirm = () => {
    dispatch(deleteProject(projectId))
      .unwrap()
      .then(() => {
        navigate('/projects');
      })
      .catch((error) => {
        // Handle deletion error
        console.error('Failed to delete project:', error);
      });
  };

  // TODO: try MUIIcons and new fontFamily and then animations
  // TODO: addd marquee on the backgorund in xl size

  return (
    <Box textAlign="left" position="relative">
      {loading ? (
        <Loader height="60vh" />
      ) : projectDetail ? (
        <>
          <DynamicBackButton />
          <CustomMarquee text={projectDetail.name} />

          <Box
            mb={{ xs: '18px', sm: '8px' }}
            pt={{ xs: '70px', sm: '110px', md: '158px' }}
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
            flexDirection={{ xs: 'column', sm: 'row' }}
            gap="12px"
          >
            <Box
              maxWidth="max-content"
              className={`project-status ${
                PROJECT_STATUS_ENUMS.includes(projectDetail?.status) // status is the correct field based on  API response
                  ? projectDetail.status.toLowerCase().replace(' ', '')
                  : ''
              }`}
              sx={{ fontSize: '14px !important' }}
            >
              {projectDetail.status}
            </Box>
          </Box>

          <Typography
            variant="h2"
            fontWeight={500}
            fontSize={{ xs: '3rem', sm: '4rem', md: '6.3rem' }}
            lineHeight={{ xs: '3.7rem', sm: '4.5rem', md: '6.7rem' }}
            maxWidth={'850px'}
          >
            {projectDetail.name}
          </Typography>

          <Typography variant="h6" fontWeight={300} maxWidth="600px" mt="52px">
            {projectDetail.description || 'Not given'}
          </Typography>

          <Box
            display="grid"
            gridTemplateColumns={{ xs: '1fr', md: '200px 1fr' }}
            gap={{ xs: '10px', md: '24px' }}
            alignItems="center"
            textAlign="left"
            mt={{ xs: '42px', sm: '62px' }}
          >
            <Typography fontWeight={500}>Start Date:</Typography>
            <Typography mb={{ xs: '16px' }}>
              {formatDate(projectDetail.start_date)}
            </Typography>

            <Typography fontWeight={500}>End Date:</Typography>
            <Typography mb={{ xs: '16px' }}>
              {moment(editedProject.end_date).isValid()
                ? formatDate(editedProject.end_date)
                : 'No ending date'}
            </Typography>

            <Typography fontWeight={500}>Project Manager:</Typography>
            <Typography mb={{ xs: '16px' }}>
              {projectDetail.project_manager
                ? `${projectDetail.project_manager?.first_name} ${projectDetail.project_manager?.last_name}`
                : '-'}
            </Typography>

            <Typography fontWeight={500}>Members:</Typography>
            <Box display="flex" alignItems="center" flexWrap="wrap" gap="8px">
              {projectDetail.members?.map((member, index) => (
                <Chip
                  key={index}
                  label={`${member.first_name} ${member.last_name}`}
                />
              ))}
            </Box>
          </Box>

          {userRole === 'Admin' && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                position: 'sticky',
                bottom: {
                  xs: LayoutSpacing.pagePaddingSm,
                  sm: LayoutSpacing.pagePadding,
                },
                padding: '12px',
                gap: '12px',
                borderRadius: '16px',
                WebkitBackdropFilter: 'saturate(180%) blur(20px)',
                backdropFilter: 'saturate(180%) blur(20px)',
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                border: `1px solid ${Colors.border}`,
                boxShadow: 'rgba(17, 12, 46, 0.1) 0px 28px 60px 0px',
                overflow: 'hidden',
                m: { xs: '32px auto 0 auto', sm: '32px 0 0 auto' },

                animationDelay: '0.05s',

                '& button': {
                  animationDelay: '0.47s',
                  '&:nth-child(2)': {
                    animationDelay: '0.52s',
                  },
                },
              }}
              className="floating-action-buttons"
            >
              <CustomButton
                className="slide-up-bounce"
                variant="outlined"
                onClick={() => setEditMode(true)}
                startIcon={<EditOutlined />}
              >
                Edit
              </CustomButton>
              <CustomButton
                className="slide-up-bounce"
                variant="outlined"
                onClick={() => setOpenDeleteDialog(true)}
                startIcon={<DeleteOutline />}
              >
                Delete
              </CustomButton>
            </Box>
          )}

          <EditProjectDialog
            open={editMode}
            onClose={() => setEditMode(false)}
            heading="Edit Project"
            editedProject={editedProject}
            handleEditChange={handleEditChange}
            setProjectManagerSearch={setProjectManagerSearch}
            onSave={handleSubmitEdit}
            dateError={dateError}
            projectDetail={projectDetail}
            employees={employees}
            setMemberSearch={setMemberSearch}
          />

          <DeleteProjectDialog
            open={openDeleteDialog}
            onClose={() => setOpenDeleteDialog(false)}
            onDelete={handleDeleteConfirm}
          />
        </>
      ) : (
        <Typography variant="body1">Project details not found.</Typography>
      )}
    </Box>
  );
}

export default ProjectDetail;
