import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllLeaves } from '../../Redux/slices/leaveSlice';
import { useNavigate } from 'react-router-dom';
import ReviewLeave from './ReviewLeave';
import { EMPLOYEE_SUBMIT_LEAVE } from '../../Routes/route_constants';
import CustomTable from '../../Components/MiscComponents/CustomTable';
import ContentWrapper from '../../Components/MiscComponents/ContentWrapper';
import { formatDate } from '../../Utils/functions';

const headers = [
  { label: 'Employee Name', keys: ['user.first_name', 'user.last_name'] },
  { label: 'Type', key: 'type' },
  {
    label: 'Start Date',
    key: 'start_date',
    format: formatDate,
  },
  {
    label: 'End Date',
    key: 'end_date',
    format: formatDate,
  },
  { label: 'Duration', key: 'duration' },
  { label: 'Status', key: 'status' },
];

const LeaveRecords = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.leaves.loading);
  const leavesResponse = useSelector((state) => state.leaves.allLeaves);
  const [currentPage, setCurrentPage] = useState(1);
  const metadata = useSelector((state) => state.leaves.metadata);

  const [selectedLeave, setSelectedLeave] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');

  useEffect(() => {
    dispatch(
      getAllLeaves({ page: currentPage, limit: 10, status: selectedStatus })
    );
  }, [dispatch, currentPage, selectedStatus]);

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
    setCurrentPage(1);
  };

  const handleRowClick = (leave) => {};

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };
  return (
    <ContentWrapper
      heading={'Leave Records'}
      isLoading={loading}
      addButtonText="Request Leave"
      addButtonOnClick={() => navigate(EMPLOYEE_SUBMIT_LEAVE)}
      hideAddIcon
      filters={[
        {
          type: 'dropdown',
          value: selectedStatus,
          onChange: handleStatusChange,
          options: [
            { value: '', text: 'All Status' },
            { value: 'Pending', text: 'Pending' },
            { value: 'Approved', text: 'Approved' },
            { value: 'Rejected', text: 'Rejected' },
          ],
        },
      ]}
    >
      <CustomTable
        headers={headers}
        data={leavesResponse?.data}
        handleRowClick={handleRowClick}
        totalPages={metadata?.totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        emptyStateMessage="No leave records available"
      />

      {isModalOpen && selectedLeave && (
        <ReviewLeave
          open={isModalOpen}
          onClose={handleModalClose}
          leave={selectedLeave}
        />
      )}
    </ContentWrapper>
  );
};

export default LeaveRecords;
