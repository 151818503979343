import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getUserById,
  updateUser,
  resetUserState,
} from '../../Redux/slices/userSlice';
import {
  Box,
  Typography,
  TextField,
  Grid,
  IconButton,
  Card,
  CardContent,
} from '@mui/material';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import DynamicBackButton from '../../Components/BackButton/DynamicBackButton';
import CustomButton from '../../Components/MiscComponents/CustomButton';
import CustomDialog from '../../Components/MiscComponents/CustomDialog';
import Loader from '../../Components/MiscComponents/Loader';
import { DatePicker } from '@mui/x-date-pickers';
import { CloseOutlined, EditOutlined } from '@mui/icons-material';

const EmployeeDetails = () => {
  const dispatch = useDispatch();
  const userDetailsResponse = useSelector((state) => state.users.selectedUser);
  const loadingUserDetails = useSelector((state) => state.users.loading);
  const errorUpdateDetails = useSelector((state) => state.users.error);
  const message = useSelector((state) => state.users.message);
  const done = useSelector((state) => state.users.done);
  const user = useSelector((state) => state.auth.currentUser?.data);
  const { _id } = useParams();

  const [userDetails, setUserDetails] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [validation, setValidation] = useState(null);

  useEffect(() => {
    if (errorUpdateDetails) {
      dispatch(resetUserState());
      setEditMode(false);
    }
  }, [errorUpdateDetails, message]);

  useEffect(() => {
    if (done) {
      dispatch(resetUserState());
      setEditMode(false);
    }
  }, [done, message]);

  useEffect(() => {
    if (!userDetailsResponse || Object.keys(userDetailsResponse).length === 0) {
      dispatch(getUserById(_id));
    }
  }, [dispatch, userDetailsResponse, _id]);

  useEffect(() => {
    if (userDetailsResponse) {
      setUserDetails(userDetailsResponse.data);
    }
  }, [userDetailsResponse]);
  useEffect(() => {
    if (!userDetailsResponse) {
      dispatch(getUserById(_id));
    } else {
      setUserDetails(userDetailsResponse);
    }
  }, [dispatch, userDetailsResponse, _id]);

  useEffect(() => {
    if (validation) {
      const valid = Object.values(validation).map((val) => {
        if (val?.length) {
          return true;
        } else return false;
      });

      setDisableBtn(valid.includes(true));
    }
  }, [validation]);

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleUpdateEmployee = () => {
    // Extracting only allowed fields
    const {
      first_name,
      last_name,
      contact_number,
      current_address,
      emergency_contact_name,
      emergency_contact_number,
      date_of_birth,
      national_id_number,
      email,
      secondary_contact_number,
      permanent_address,
    } = userDetails;

    // Constructing the data object with allowed fields
    const data = {
      first_name,
      last_name,
      contact_number,
      current_address,
      emergency_contact_name,
      emergency_contact_number,
      date_of_birth,
      national_id_number,
      email,
      secondary_contact_number,
      permanent_address,
    };

    // Dispatching the update request with the constructed data object
    dispatch(updateUser({ id: _id, data }));
    setOpenDialog(false);

    // Show toast notification
    toast.success('User updated successfully', {
      position: toast.POSITION.TOP_RIGHT,
    });

    // Reset edit mode to false
    setEditMode(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <DynamicBackButton />

      {loadingUserDetails ? (
        <Loader height="60vh" />
      ) : (
        <>
          <Card
            className="no-hover"
            sx={{
              maxWidth: '724px',
              mx: 'auto',
              mt: { xs: '20px', sm: '0px' },
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: '42px',
                }}
              >
                <Typography variant="h4" fontWeight={500} textAlign="left">
                  Employee Information
                </Typography>

                <IconButton onClick={toggleEditMode} color="primary">
                  {editMode ? <CloseOutlined /> : <EditOutlined />}
                </IconButton>
              </Box>

              <Grid container spacing={'18px'}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="First Name"
                    value={userDetails?.first_name || ''}
                    onChange={(e) =>
                      setUserDetails({
                        ...userDetails,
                        first_name: e.target.value,
                      })
                    }
                    disabled={!editMode}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Last Name"
                    value={userDetails?.last_name || ''}
                    onChange={(e) =>
                      setUserDetails({
                        ...userDetails,
                        last_name: e.target.value,
                      })
                    }
                    disabled={!editMode}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Email"
                    value={userDetails?.email || ''}
                    onChange={(e) =>
                      setUserDetails({
                        ...userDetails,
                        email: e.target.value,
                      })
                    }
                    disabled={!editMode}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    label="Date of Birth"
                    value={new Date(userDetails?.date_of_birth) || null}
                    onChange={(date) =>
                      setUserDetails({
                        ...userDetails,
                        date_of_birth: date,
                      })
                    }
                    renderInput={(params) => <TextField {...params} />}
                    disabled={!editMode}
                    sx={{ width: '100%' }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Contact Number"
                    value={userDetails?.contact_number || ''}
                    onChange={(e) =>
                      setUserDetails({
                        ...userDetails,
                        contact_number: e.target.value,
                      })
                    }
                    disabled={!editMode}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Secondary Contact Number"
                    value={userDetails?.secondary_contact_number || ''}
                    onChange={(e) =>
                      setUserDetails({
                        ...userDetails,
                        secondary_contact_number: e.target.value,
                      })
                    }
                    disabled={!editMode}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Permanent Address"
                    value={userDetails?.permanent_address || ''}
                    onChange={(e) =>
                      setUserDetails({
                        ...userDetails,
                        permanent_address: e.target.value,
                      })
                    }
                    disabled={!editMode}
                    fullWidth
                  />
                </Grid>

                {/* Add other fields here */}
                <Grid item xs={12}>
                  <TextField
                    label="Current Address"
                    value={userDetails?.current_address || ''}
                    onChange={(e) =>
                      setUserDetails({
                        ...userDetails,
                        current_address: e.target.value,
                      })
                    }
                    disabled={!editMode}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Emergency Contact Name"
                    value={userDetails?.emergency_contact_name || ''}
                    onChange={(e) =>
                      setUserDetails({
                        ...userDetails,
                        emergency_contact_name: e.target.value,
                      })
                    }
                    disabled={!editMode}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Emergency Contact Number"
                    value={userDetails?.emergency_contact_number || ''}
                    onChange={(e) =>
                      setUserDetails({
                        ...userDetails,
                        emergency_contact_number: e.target.value,
                      })
                    }
                    disabled={!editMode}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="National ID Number"
                    value={userDetails?.national_id_number || ''}
                    onChange={(e) =>
                      setUserDetails({
                        ...userDetails,
                        national_id_number: e.target.value,
                      })
                    }
                    disabled={!editMode}
                    fullWidth
                  />
                </Grid>
              </Grid>

              {editMode && (
                <CustomButton
                  disabled={disableBtn}
                  onClick={handleOpenDialog}
                  fullWidth
                  sx={{ mt: '32px' }}
                >
                  Update
                </CustomButton>
              )}
            </CardContent>
          </Card>

          <CustomDialog
            open={openDialog}
            onClose={handleCloseDialog}
            heading="Confirm Update"
            subHeading="Are you sure you want to update the changes?"
          >
            <Box display="flex" justifyContent="flex-end" mt={'42px'} gap="8px">
              <CustomButton onClick={handleCloseDialog} variant="outlined">
                Cancel
              </CustomButton>
              <CustomButton onClick={handleUpdateEmployee}>Update</CustomButton>
            </Box>
          </CustomDialog>
        </>
      )}
    </>
  );
};

export default EmployeeDetails;
