import AllRoutes from './Routes/AllRoutes';
import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import theme from './theme';
import { useDispatch } from 'react-redux';
import { getCurrentUser } from './Redux/slices/authSlice';
import { ToastContainer } from 'react-toastify';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import './App.css';
import './animations.css';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    isUserLoggedIn();
  }, []);

  const isUserLoggedIn = async () => {
    await dispatch(getCurrentUser());
    setLoading(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
        <ToastContainer hideProgressBar />
        <div className="App">{loading ? <></> : <AllRoutes />}</div>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
