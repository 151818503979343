import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
} from '@mui/material';
import { addNewProject } from '../../Redux/slices/projectSlice';
import { getAllUsers } from '../../Redux/slices/userSlice';
import { PROJECT_BASE_URL } from '../../Routes/route_constants';
import { PROJECT_STATUS_OBJ_ENUMS } from '../../Utils/Constants';
import { toast } from 'react-toastify';
import { useCallback } from 'react';
import moment from 'moment';
import CustomDialog from '../../Components/MiscComponents/CustomDialog';
import CustomButton from '../../Components/MiscComponents/CustomButton';

function AddProjectModal({ onClose }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [projectManagerSearch, setProjectManagerSearch] = useState('');
  const [memberSearch, setMemberSearch] = useState('');
  const usersResponse = useSelector((state) => state.users.allUsers);
  const [employees, setEmployees] = useState([]);
  const fetchUsersDebounced = useCallback(
    debounce((search) => fetchUsers(search), 500),
    []
  );
  const [dateError, setDateError] = useState(false);
  const [formValues, setFormValues] = useState({
    name: '',
    description: '',
    start_date: '',
    end_date: '',
    project_manager: null,
    members: [],
    status: '',
  });

  // Function to fetch users based on search
  const fetchUsers = (search) => {
    dispatch(getAllUsers({ filters: { search: search }, page: 1 }));
  };

  function debounce(func, wait) {
    let timeout;

    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }
  useEffect(() => {
    fetchUsersDebounced(projectManagerSearch);
  }, [projectManagerSearch, fetchUsersDebounced]);

  useEffect(() => {
    fetchUsersDebounced(memberSearch);
  }, [memberSearch, fetchUsersDebounced]);

  useEffect(() => {
    if (usersResponse) {
      const formattedUsers = usersResponse.map((user) => ({
        label: `${user.first_name} ${user.last_name}`,
        value: user.id,
      }));
      setEmployees(formattedUsers);
    }
  }, [usersResponse]);

  const handleChange = (name, value) => {
    if (name === 'start_date' || name === 'end_date') {
      const start = name === 'start_date' ? value : formValues.start_date;
      const end = name === 'end_date' ? value : formValues.end_date;
      setDateError(moment(end).isBefore(moment(start)));
    }
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const projectData = {
      ...formValues,
      project_manager: formValues.project_manager?.value,
      members: formValues.members.map((member) => member.value),
    };

    try {
      await dispatch(addNewProject(projectData)).unwrap();
      toast.success('Project added successfully');
      navigate(PROJECT_BASE_URL);
      onClose();
    } catch (error) {
      toast.error('Failed to add project: ' + error.message);
    }
  };

  return (
    <CustomDialog open={true} onClose={onClose} heading="Add New Project">
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            mt: '24px',
            display: 'flex',
            flexDirection: 'column',
            gap: '18px',
          }}
        >
          <TextField
            fullWidth
            label="Project Name"
            variant="outlined"
            id="name"
            value={formValues.name}
            onChange={(e) => handleChange('name', e.target.value)}
          />
          <TextField
            fullWidth
            label="Description"
            variant="outlined"
            id="description"
            value={formValues.description}
            onChange={(e) => handleChange('description', e.target.value)}
            multiline
            rows={4}
          />
          <Box display="grid" gridTemplateColumns="1fr 1fr" gap="inherit">
            <TextField
              fullWidth
              label="Start Date"
              type="date"
              variant="outlined"
              id="start_date"
              InputLabelProps={{
                shrink: true,
              }}
              value={formValues.start_date}
              onChange={(e) => handleChange('start_date', e.target.value)}
            />
            <TextField
              error={dateError}
              helperText={
                dateError ? "End date can't be before start date" : ''
              }
              fullWidth
              label="End Date"
              type="date"
              variant="outlined"
              id="end_date"
              InputLabelProps={{
                shrink: true,
              }}
              value={formValues.end_date}
              onChange={(e) => handleChange('end_date', e.target.value)}
            />
          </Box>
          <Autocomplete
            options={employees}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onInputChange={(event, newInputValue) => {
              setProjectManagerSearch(newInputValue);
            }}
            onChange={(event, newValue) => {
              handleChange('project_manager', newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Project Manager" fullWidth />
            )}
          />
          <Autocomplete
            multiple
            options={employees}
            getOptionLabel={(option) => option.label}
            filterSelectedOptions
            onInputChange={(event, newInputValue) => {
              setMemberSearch(newInputValue);
            }}
            onChange={(event, newValue) => {
              handleChange('members', newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Project Members" fullWidth />
            )}
          />
          <FormControl fullWidth>
            <InputLabel id="status-label">Project Status</InputLabel>
            <Select
              labelId="status-label"
              id="status"
              value={formValues.status}
              label="Project Status"
              onChange={(event) => handleChange('status', event.target.value)}
            >
              {PROJECT_STATUS_OBJ_ENUMS.map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box display="flex" justifyContent="flex-end" mt="42px">
          <CustomButton type="submit">Add Project</CustomButton>
        </Box>
      </form>
    </CustomDialog>
  );
}

export default AddProjectModal;
