import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const BASE_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

export const getAllWorkLogs = createAsyncThunk(
  'workLogs/getAllWorkLogs',
  async (
    { userId, startDate, endDate, projectId, page = 1, limit = 10 },
    { rejectWithValue }
  ) => {
    try {
      const params = { startDate, endDate, page, limit };
      if (projectId) params.project = projectId;

      const response = await axios.get(`/v1/users/${userId}/work-logs`, {
        params,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createMultipleWorkLogs = createAsyncThunk(
  'workLogs/createMultipleWorkLogs',
  async ({ userId, workLogsData }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/v1/users/${userId}/work-logs`,
        workLogsData,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  workLogs: [],
  loading: false,
  error: null,
  message: '',
  metadata: {
    totalResults: 0,
    totalPages: 0,
    currentPage: 0,
    limit: 10, // Default limit
  },
};

const workLogsSlice = createSlice({
  name: 'workLogs',
  initialState,
  reducers: {
    resetWorkLogsState: () => initialState, // Use initialState here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllWorkLogs.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllWorkLogs.fulfilled, (state, action) => {
        state.loading = false;
        state.workLogs = action.payload.data;
        state.metadata = action.payload.metadata; // Set metadata from the response
      })
      .addCase(getAllWorkLogs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createMultipleWorkLogs.pending, (state) => {
        state.loading = true;
      })
      .addCase(createMultipleWorkLogs.fulfilled, (state, action) => {
        state.loading = false;
        state.workLogs.push(action.payload); //new work log is returned
        state.message = 'Work log created successfully.';
      })
      .addCase(createMultipleWorkLogs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetWorkLogsState } = workLogsSlice.actions;
export default workLogsSlice.reducer;
