import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import usersSlice from './slices/userSlice';
import projectSlice from './slices/projectSlice';
import authSlice from './slices/authSlice';
import companyResourceSlice from './slices/companyResourceSlice';
import fileSlice from './slices/fileSlice';
import appraisalSlice from './slices/appraisalSlice';
import leaveSlice from './slices/leaveSlice';
import slackSlice from './slices/slackSlice';
import reimbursementSlice from './slices/reimbursementSlice';
import attendanceSlice from './slices/attendanceSlice';
import workLogsSlice from './slices/workLogsSlice';

export const store = configureStore({
  reducer: {
    users: usersSlice,
    auth: authSlice,
    projects: projectSlice,
    companyResource: companyResourceSlice,
    files: fileSlice,
    appraisals: appraisalSlice,
    leaves: leaveSlice,
    slack: slackSlice,
    reimbursement: reimbursementSlice,
    attendance: attendanceSlice,
    workLogs: workLogsSlice,
  },
  middleware: [...getDefaultMiddleware({ serializableCheck: false })],
});
