import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginUser, resetGenericStates } from '../../Redux/slices/authSlice';
import { toast } from 'react-toastify';
import { TextField, Typography } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import CustomButton from '../../Components/MiscComponents/CustomButton';
import UnAuthPageLayout from '../../Components/PageLayout/UnAuthPageLayout';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { done, error, message, loading } = useSelector((state) => state.auth);
  const currentUser = useSelector((state) => state.auth.currentUser?.data);

  // Effect for showing toast messages
  useEffect(() => {
    if (done) {
      dispatch(resetGenericStates());
    } else if (error) {
      toast.error(message);
      dispatch(resetGenericStates());
    }
  }, [dispatch, done, error, message]);

  // Effect for redirecting based on authentication state
  useEffect(() => {
    if (currentUser) {
      navigate('/dashboard', { replace: true });
    }
  }, [currentUser, navigate]);

  const initialValues = {
    email: '',
    password: '',
  };
  const onForgotPasswordClick = () => {
    // Navigate to your forgot password route
    navigate('/forget-password');
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const onSubmit = (values) => {
    dispatch(loginUser(values));
  };

  return (
    <UnAuthPageLayout>
      <Typography variant="h5" textAlign="left" fontWeight={500} mb="42px">
        Hi, Welcome Back!
      </Typography>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ getFieldProps, errors, touched }) => (
          <Form>
            <Field
              as={TextField}
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              {...getFieldProps('email')}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              style={{ margin: 0, minHeight: '70px', marginBottom: '10px' }}
            />
            <Field
              as={TextField}
              variant="outlined"
              margin="normal"
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              {...getFieldProps('password')}
              error={touched.password && Boolean(errors.password)}
              helperText={touched.password && errors.password}
              style={{ margin: 0, minHeight: '70px' }}
            />
            <CustomButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: '12px' }}
              isSaving={loading}
            >
              {loading ? 'Logging In' : 'Log in'}
            </CustomButton>
          </Form>
        )}
      </Formik>

      <Typography
        variant="body2"
        color="grey"
        align="center"
        fontWeight={300}
        mt="28px"
      >
        Don't remember your account password?{' '}
        <Typography
          component="span"
          sx={{ cursor: 'pointer', textDecoration: 'underline' }}
          onClick={onForgotPasswordClick}
          fontSize="inherit"
          fontWeight="inherit"
          color="primary.contrastText"
        >
          <nobr>Reset Password</nobr>
        </Typography>
      </Typography>
    </UnAuthPageLayout>
  );
};

export default Login;
