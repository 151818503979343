import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { useState } from 'react';
import LayoutHeader from './LayoutHeader';
import LayoutSidebar from './LayoutSidebar';
import { DRAWER_WIDTH as drawerWidth } from '../../Utils/Constants';
import LayoutSpacing from '../../Utils/LayoutSpacing';

const PageLayout = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = (state) =>
    setMobileOpen(typeof state !== 'undefined' ? state : !mobileOpen);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <LayoutHeader handleSidedrawer={handleDrawerToggle} />

      <LayoutSidebar
        open={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />

      <Box
        component="main"
        sx={(theme) => ({
          p: LayoutSpacing.pagePadding,

          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          [theme.breakpoints.down('sm')]: {
            p: LayoutSpacing.pagePaddingSm,
            pt: '32px',
          },
        })}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

export default PageLayout;
